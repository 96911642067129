import React, { useState, createContext, useEffect } from 'react';

export const MembershipContext = createContext();

export const MembershipProvider = ({ children }) => {
  const defaultMembership = 'Top Online Estándar'; // Ajusta este valor según tu necesidad
  const initialMembership = localStorage.getItem('selectedMembership') || defaultMembership;
  const [selectedMembership, setSelectedMembership] = useState(initialMembership);

  useEffect(() => {
    if (selectedMembership) {
      localStorage.setItem('selectedMembership', selectedMembership);
    }
  }, [selectedMembership]);

  return (
    <MembershipContext.Provider value={{ selectedMembership, setSelectedMembership }}>
      {children}
    </MembershipContext.Provider>
  );
};
